import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Fun99() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the homepage
    navigate('/');
  }, [navigate]);

  // This return statement won't be rendered as the component will redirect immediately
  return null;
}